@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  ,
  body {
    @apply text-base font-mulish;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
}

@layer utilities {
  .transition-5-ease-in-out {
    transition: all .5s ease-in-out;
  }

  .transition-5-ease {
    transition: all .5s ease;
  }
  
  .word-spacing-1 {
    word-spacing: 0.1em;
  }
}