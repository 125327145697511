.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.hamburger {
  margin: 0 auto;
  width: 24px;
  height: 20px;
  position: relative;
}

.hamburger .bar {
  padding: 0;
  width: 24px;
  height: 2px;
  background-color: #2C88D9;
  display: block;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  position: absolute;
}

.bar1 {
  top: 0;
}

.bar2,
.bar3 {
  top: 7.5px;
}

.bar3 {
  right: 0;
}

.bar4 {
  bottom: 0;
}

.hamburger .bar4 {
  top: 7.5px;
}

.hamburger .bar5 {
  top: 15px;
}

.hamburger .bar {
  transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}

.hamburger .bar2 {
  width: 1px;
  transform: rotate(90deg);
  left: 12px;
}

.hamburger .bar3 {
  width: 1px;
  left: 12px;
}

.checkbox:checked+label>.hamburger>.bar1 {
  top: 13.5px;
  background-color: transparent;
}

.checkbox:checked+label>.hamburger>.bar2 {
  left: 0px;
  width: 24px;
  transform: rotate(45deg);
}

.checkbox:checked+label>.hamburger>.bar3 {
  left: 0;
  width: 24px;
  transform: rotate(-45deg);
}

.checkbox:checked+label>.hamburger>.bar4 {
  background-color: transparent;
}

.checkbox:checked+label>.hamburger>.bar5 {
  bottom: 13.5px;
  background-color: transparent;
}